import { useStaticQuery, graphql } from "gatsby";

export const useAllArticles = () => {
    const { articles } = useStaticQuery(
        graphql`
            query {
                articles: allMdx (
                    filter: {frontmatter: {publish: {eq: true}}}
                    sort: { fields: frontmatter___date, order: DESC }
                ) {
                    edges {
                        node {
                            id
                            frontmatter {
                                name

                                description
                            }
                            fields {
                                slug
                            }
                            imageFile1 {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 400
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP]
                                    )
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    return articles.edges;
}
