import React from "react";

import { Articles } from "../../components/articles";
import { useAllArticles } from "../../hooks/use-all-articles";
import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";
import ErrorTemplate from "../error";

const ArticlesTemplate = ({ pageContext }) => {
    const articles = useAllArticles();

    const pageDetails = {
        currentLanguageRegion: pageContext.currentLanguageRegion,
        title: pageContext.title,
        meta: pageContext.meta,
        script: pageContext.script,
        urlPath: pageContext.urlPath
    };

    if (articles !== null) {
        return (
            <DefaultLayout pageDetails={pageDetails}>
                <ContentLayout title="Articles">
                    <Articles articles={articles} />
                </ ContentLayout>
            </DefaultLayout>
        );
    }

    return (
        <ErrorTemplate />
    );
};

export default ArticlesTemplate;
