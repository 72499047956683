import React from "react";

import { Link } from "gatsby";

import { ShopNow } from "../../components/buttons/shop-now";
import { Image } from "../image";

export const Box = (props) => {
    const containerClasses = props && props.containerClass ? `u-text-over-image__container c-content__container c-content__container--home-primary ${props.containerClass}` : "u-text-over-image__container c-content__container c-content__container--home-primary";
    const linkClasses = props && props.linkClass ? `g-flex__container ${props.linkClass}` : "g-flex__container";
    const imgClasses = props && props.imgClass ? `g-flex__item ${props.imgClass}` : "g-flex__item";
    const textWrapperClasses = props && props.textWrapperClass ? `g-flex__item c-content__text-container u-text-over-image__text u-text-over-image__text--bottom ${props.textWrapperClass}` : "g-flex__item c-content__text-container u-text-over-image__text u-text-over-image__text--bottom";
    const titleClasses = props.headingTextMobile ? "c-content__title--mobile" : "c-content__title";

    return (
        <div className={containerClasses}>
            <Link to={props.linkUri} className={linkClasses}>
                <Image imgClassName={imgClasses} alt={props.imgAlt} image={props.image} />
                <div className={textWrapperClasses}>
                    <h2 className={titleClasses}>{props.headingText}</h2>
                    <p className="c-title__subtitle">{props.subHeadingText}</p>
                    <ShopNow componentClass={props.buttonComponentClass} buttonClass={props.buttonClass} label={props.buttonLabel}
                    >{props.buttonText}</ShopNow>
                </div>
            </Link>
        </div>
    );
};

