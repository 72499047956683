import React from "react";

import { CountrySelector } from "../../components/country-selector";
import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";


import "./error.scss";

const ErrorTemplate = (props) => {
    return (
        <DefaultLayout pageDetails={props.pageDetails}>
            <ContentLayout title={props.title}>
                {props.productFilter}
                <div className="c-error__container g-flex__container g-flex__container--column-always u-padding-top--2">
                    <span className="g-flex__item e-fake-heading">Products are not currently available in your region of the world.</span>
                    {props.errorMessage && <span className="u-padding-top--1 g-flex__item">{props.errorMessage}</span>}
                    <span className="u-padding-top--1 g-flex__item">We are currently seeking partners in the United States of America, Australia, Canada, New Zeland and the United Kingdom. Please visit our United Kingdom region until we have established partnerships in these other regions.</span>
                    {props.languageRegion && props.updateLanguageRegion &&
                        <CountrySelector vertical={true} linksOnly={true} languageRegion={props.languageRegion} updateLanguageRegion={props.updateLanguageRegion} />
                    }
                </div>
            </ContentLayout >

        </DefaultLayout >
    );
};

export default ErrorTemplate;
