import React, { useContext } from "react";

import { Box } from "../../components/boxes/primary.js";
import { LanguageRegionContext } from "../../context-providers/language-region-context";
import { getPath } from "../../helpers/get-path";
import "./articles.scss";

export const Articles = ({ articles }) => {
    const { languageRegion } = useContext(LanguageRegionContext);

    return (
        <ul className="c-articles g-flex__container g-flex__container--column-mobile-only">
            {articles.map((item) => {
                const article = item.node;

                return (
                    <li key={`article-${article.fields.slug}`} className="c-articles__item g-flex__item g-flex__item--grow u-margin-top--2">
                        {article && article.frontmatter && article.frontmatter.name && article.fields.slug && article.frontmatter.description &&
                            <Box
                                containerClass="c-content__container--home-primary"
                                linkUri={getPath(languageRegion, `articles/${article.fields.slug}`)}
                                linkClass="g-grid__container--content-primary"
                                imgClass="g-grid__item--content-image c-content__image"
                                imgAlt={article.frontmatter.name}
                                image={article.imageFile1}
                                textWrapperClass="g-grid__item--content-text"
                                headingText={article.frontmatter.name}
                                subHeadingText={article.frontmatter.description}
                                buttonLabel={`${article.frontmatter.name} Full Article`}
                                buttonText="Full Article"
                                buttonClass="c-shop-now-button__link--secondary-color"
                            />}
                    </li>
                );
            })}
        </ul>
    );
};
